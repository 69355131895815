<template>
  <loading v-if="loading" :loading="loading" />
  <div v-else>
    <list
      v-for="relationship in relationships"
      :key="`${relationship.id}-relationship`"
      middle-aligned
    >
      <flex align="center" class="relationship">
        <span class="relationship-name"> {{ relationship.name }}</span>
        <btn
          icon="fas fa-plus"
          :tooltip="`Add ${relationship.name}`"
          @click.native="() => $emit('add-users-relationship', relationship.id)"
        />
      </flex>
      <list-item
        v-for="usersRelationship in store.get(relationship.id) || []"
        :key="usersRelationship.id"
      >
        <list-content>
          <flex justify="space-between" align="center" wrap="wrap">
            <div>
              <dropdown
                name="Add Role One"
                :default-text="`Select ${
                  relationship.superiorTitle || 'Person'
                }`"
                class="relationship-dropdown"
                scrolling
                floating
                hide-caret
                :initial-value="usersRelationship.firstUserId"
                @update="
                  (value) =>
                    $emit('update-users-relationship', {
                      relationshipId: relationship.id,
                      usersRelationshipId: usersRelationship.id,
                      field: 'firstUserId',
                      value,
                    })
                "
              >
                <dropdown-search />
                <dropdown-item
                  v-for="user in users"
                  :key="user.id"
                  :value="user.id"
                >
                  <g-image
                    avatar
                    :src="user.avatar || defaultUserAvatar"
                    alt="Role one avatar for relationship"
                  />
                  {{ user.fullName }}
                </dropdown-item>
              </dropdown>
              <i class="fas fa-long-arrow-alt-right grey connection-icon" />
              <dropdown
                name="Add Role Two"
                :default-text="`Select ${
                  relationship.subordinateTitle || 'Person'
                }`"
                scrolling
                floating
                hide-caret
                class="relationship-dropdown"
                :initial-value="usersRelationship.secondUserId"
                @update="
                  (value) =>
                    $emit('update-users-relationship', {
                      relationshipId: relationship.id,
                      usersRelationshipId: usersRelationship.id,
                      field: 'secondUserId',
                      value,
                    })
                "
              >
                <dropdown-search />
                <dropdown-item
                  v-for="user in users"
                  :key="user.id"
                  :value="user.id"
                >
                  <g-image
                    avatar
                    :src="user.avatar || defaultUserAvatar"
                    alt="Role two avatar for relationship"
                  />
                  {{ user.fullName }}
                </dropdown-item>
              </dropdown>
            </div>
            <btn
              dismiss
              tooltip="Remove"
              compact
              @click.native="
                () =>
                  $emit('delete-users-relationship', {
                    relationshipId: relationship.id,
                    usersRelationshipId: usersRelationship.id,
                  })
              "
            />
          </flex>
        </list-content>
      </list-item>
    </list>
  </div>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'
import gImage from '@/components/v2/image.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import listContent from '@/components/v2/list/content.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import dropdownSearch from '@/components/v2/dropdown/search.vue'
import loading from '@/components/v2/loading.vue'

import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'

export default {
  components: {
    flex,
    btn,
    gImage,
    list,
    listItem,
    listContent,
    dropdown,
    dropdownItem,
    dropdownSearch,
    loading,
  },
  props: {
    store: {
      type: Map,
      required: true,
    },
  },
  data() {
    return {
      defaultUserAvatar,
      relationships: [],
      users: [],
      relationshipsLoading: false,
      usersLoading: false,
    }
  },
  computed: {
    loading() {
      return this.relationshipsLoading || this.usersLoading
    },
  },
  mounted() {
    this.getSection()
  },
  methods: {
    async getSection() {
      await this.getRelationships()
      await this.getUsers()
    },
    async getRelationships() {
      try {
        this.relationshipsLoading = true
        this.relationships = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships`, {
            page: parseInt(this.$route.query.page || 1, 10),
            sort: 'name,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.relationshipsLoading = false
      }
    },
    async getUsers(search) {
      let response
      this.usersLoading = true
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              ...(search ? { search } : {}),
              status: ['active', 'onboarding'],
              sort: 'preferredFirstName,lastName,id',
            }
          )
          this.users = this.users.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
      this.usersLoading = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/text.less';
@import '~@/assets/less/colors.less';

.connection-icon {
  margin: 0 1.5rem;
}

.relationship,
.relationship-dropdown {
  margin: 1rem 0;
}

.relationship-name {
  font-size: @title-font-size;
  font-weight: bold;
}
</style>
