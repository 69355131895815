<template>
  <flex
    align="center"
    justify="center"
    :style="{ 'background-color': hexColor }"
    :class="[size, backgroundColorClass, 'square']"
  >
    <i :class="[icon, iconSize, 'icon']" />
  </flex>
</template>

<script>
import flex from '@/components/v2/flex.vue'

export default {
  components: {
    flex,
  },
  props: {
    size: {
      type: String,
      default: null,
      validator: (value) =>
        ['small', 'medium', 'large', 'big', 'huge', 'massive'].includes(value),
    },
    // Both hex code or a background color class from color.less are used across the app.
    // Both are supported with separate props, but hex code should be phased out.
    backgroundColorClass: {
      type: String,
      default: null,
    },
    hexColor: {
      type: String,
      default: null,
    },
    // Font Awesome icon name (ex. fas fa-baby) are preferred over Fomantic icon names
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    // Due to conflicts between the "medium" brand icon in font awesome,
    // the icon needs to be set to blank or default to be the medium size.
    iconSize() {
      return this.size === 'medium' ? '' : this.size
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';

.square {
  flex-shrink: 0;
  border-radius: @standard-border-radius;

  &.small {
    width: 1.5em;
    height: 1.5em;
  }

  &.medium {
    width: 2em;
    height: 2em;
  }

  &.large {
    width: 3em;
    height: 3em;
  }

  &.big {
    width: 4.5em;
    height: 4.5em;
  }

  &.huge {
    width: 7em;
    height: 7em;
    border-radius: @big-border-radius;
  }

  &.massive {
    width: 14em;
    height: 14em;
    border-radius: @big-border-radius;
  }
}

.icon {
  margin: 0;
  color: @blue;
}

.blue-background {
  .icon {
    color: @white;
  }
}
</style>
