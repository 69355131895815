<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    pointing: {
      type: Boolean,
      default: false,
    },
    attached: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        menu: true,
        tabular: true,
        secondary: true,
        pointing: this.pointing,
        top: this.attached,
        attached: this.attached,
        centered: this.centered,
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ui.menu {
  margin: 0;
}

.centered {
  justify-content: center;
}
</style>
