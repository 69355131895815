<template>
  <td :class="classes">
    <slot />
  </td>
</template>

<script>
export default {
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    collapsing: {
      type: Boolean,
      default: false,
    },
    rightAligned: {
      type: Boolean,
      default: false,
    },
    centerAligned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        error: this.error,
        selectable: this.selectable,
        collapsing: this.collapsing,
        'right aligned': this.rightAligned,
        'center aligned': this.centerAligned,
      }
    },
  },
}
</script>
