<template>
  <router-link :to="to" :class="classes" @click.native="$emit('page', page)">
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  computed: {
    classes() {
      return {
        item: true,
        active: this.active,
        disabled: this.disabled,
      }
    },
  },
}
</script>
