<template>
  <div class="ui icon search input">
    <i class="search icon" />
    <input type="text" :placeholder="placeholder" />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Search...',
    },
  },
}
</script>
