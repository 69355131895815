<template>
  <a :class="classes" @click="onClick">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fragment: {
      type: String,
      required: true,
    },
  },
  computed: {
    classes() {
      return {
        item: true,
        active: this.active,
        disabled: this.disabled,
      }
    },
  },
  methods: {
    onClick() {
      if (!this.active) {
        this.$router.push({
          name: this.$route.name,
          query: this.$route.query.page
            ? { ...this.$route.query, ...{ page: 1 } }
            : this.$route.query,
          hash: `#${encodeURIComponent(this.fragment)}`,
        })
      }
    },
  },
}
</script>
