var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination-container"},[(_vm.numOfPages > _vm.pageWindow)?_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
      'fas fa-angle-double-left pagination-icon',
      { disabled: _vm.disablePrevious } ],attrs:{"content":"First Page"},on:{"click":function($event){return _vm.$router.push({
        name: _vm.$route.name,
        query: Object.assign({}, _vm.$route.query, { page: 1 }),
        hash: _vm.$route.hash,
      })}}}):_vm._e(),_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
      'fas fa-angle-left pagination-icon',
      { disabled: _vm.disablePrevious } ],attrs:{"content":"Previous Page"},on:{"click":function($event){return _vm.$router.push({
        name: _vm.$route.name,
        query: Object.assign({}, _vm.$route.query, { page: _vm.previous }),
        hash: _vm.$route.hash,
      })}}}),_c('div',{class:_vm.classes},_vm._l((_vm.pages),function(page){return _c('pagination-item',{key:page,attrs:{"page":page,"active":page === _vm.currentPage,"to":{
        name: _vm.$route.name,
        query: Object.assign({}, _vm.$route.query, { page: page }),
        hash: _vm.$route.hash,
      }}},[_vm._v(" "+_vm._s(page)+" ")])}),1),_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],class:['fas fa-angle-right pagination-icon', { disabled: _vm.disableNext }],attrs:{"content":"Next Page"},on:{"click":function($event){return _vm.$router.push({
        name: _vm.$route.name,
        query: Object.assign({}, _vm.$route.query, { page: _vm.next }),
        hash: _vm.$route.hash,
      })}}}),(_vm.numOfPages > _vm.pageWindow)?_c('i',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
      'fas fa-angle-double-right pagination-icon',
      { disabled: _vm.disableNext } ],attrs:{"content":"Last Page"},on:{"click":function($event){return _vm.$router.push({
        name: _vm.$route.name,
        query: Object.assign({}, _vm.$route.query, { page: _vm.numOfPages }),
        hash: _vm.$route.hash,
      })}}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }