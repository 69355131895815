var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loading',{attrs:{"loading":_vm.loading}}):_c('div',_vm._l((_vm.relationships),function(relationship){return _c('list',{key:((relationship.id) + "-relationship"),attrs:{"middle-aligned":""}},[_c('flex',{staticClass:"relationship",attrs:{"align":"center"}},[_c('span',{staticClass:"relationship-name"},[_vm._v(" "+_vm._s(relationship.name))]),_c('btn',{attrs:{"icon":"fas fa-plus","tooltip":("Add " + (relationship.name))},nativeOn:{"click":function($event){return (function () { return _vm.$emit('add-users-relationship', relationship.id); }).apply(null, arguments)}}})],1),_vm._l((_vm.store.get(relationship.id) || []),function(usersRelationship){return _c('list-item',{key:usersRelationship.id},[_c('list-content',[_c('flex',{attrs:{"justify":"space-between","align":"center","wrap":"wrap"}},[_c('div',[_c('dropdown',{staticClass:"relationship-dropdown",attrs:{"name":"Add Role One","default-text":("Select " + (relationship.superiorTitle || 'Person')),"scrolling":"","floating":"","hide-caret":"","initial-value":usersRelationship.firstUserId},on:{"update":function (value) { return _vm.$emit('update-users-relationship', {
                    relationshipId: relationship.id,
                    usersRelationshipId: usersRelationship.id,
                    field: 'firstUserId',
                    value: value,
                  }); }}},[_c('dropdown-search'),_vm._l((_vm.users),function(user){return _c('dropdown-item',{key:user.id,attrs:{"value":user.id}},[_c('g-image',{attrs:{"avatar":"","src":user.avatar || _vm.defaultUserAvatar,"alt":"Role one avatar for relationship"}}),_vm._v(" "+_vm._s(user.fullName)+" ")],1)})],2),_c('i',{staticClass:"fas fa-long-arrow-alt-right grey connection-icon"}),_c('dropdown',{staticClass:"relationship-dropdown",attrs:{"name":"Add Role Two","default-text":("Select " + (relationship.subordinateTitle || 'Person')),"scrolling":"","floating":"","hide-caret":"","initial-value":usersRelationship.secondUserId},on:{"update":function (value) { return _vm.$emit('update-users-relationship', {
                    relationshipId: relationship.id,
                    usersRelationshipId: usersRelationship.id,
                    field: 'secondUserId',
                    value: value,
                  }); }}},[_c('dropdown-search'),_vm._l((_vm.users),function(user){return _c('dropdown-item',{key:user.id,attrs:{"value":user.id}},[_c('g-image',{attrs:{"avatar":"","src":user.avatar || _vm.defaultUserAvatar,"alt":"Role two avatar for relationship"}}),_vm._v(" "+_vm._s(user.fullName)+" ")],1)})],2)],1),_c('btn',{attrs:{"dismiss":"","tooltip":"Remove","compact":""},nativeOn:{"click":function($event){return (function () { return _vm.$emit('delete-users-relationship', {
                  relationshipId: relationship.id,
                  usersRelationshipId: usersRelationship.id,
                }); }).apply(null, arguments)}}})],1)],1)],1)})],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }