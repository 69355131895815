<template>
  <loading v-if="pageLoading" :loading="pageLoading" />
  <container v-else>
    <page-header>
      <grid centered>
        <grid-column centered>
          <h1>Add {{ newUser && newUser.fullName }}</h1>
        </grid-column>
      </grid>
    </page-header>
    <tab-list centered>
      <tab
        :disabled="$route.hash !== '#information'"
        fragment="information"
        :active="$route.hash === '#information'"
      >
        Information
      </tab>
      <tab
        :disabled="$route.hash !== '#custom-fields'"
        fragment="custom-fields"
        :active="$route.hash === '#custom-fields'"
      >
        Custom Fields
      </tab>
      <tab
        :disabled="$route.hash !== '#relationships'"
        fragment="relationships"
        :active="$route.hash === '#relationships'"
      >
        Relationships
      </tab>
      <tab
        :disabled="$route.hash !== '#groups'"
        fragment="groups"
        :active="$route.hash === '#groups'"
      >
        Groups
      </tab>
      <tab
        :disabled="$route.hash !== '#moments'"
        fragment="moments"
        :active="$route.hash === '#moments'"
      >
        Moments
      </tab>
      <tab
        :disabled="$route.hash !== '#confirmation'"
        fragment="confirmation"
        :active="$route.hash === '#confirmation'"
      >
        Confirmation
      </tab>
    </tab-list>
    <add-information
      v-if="$route.hash === '#information'"
      :store="userStore"
      class="block"
      @update-information="updateInformation"
    />
    <add-custom-fields
      v-if="$route.hash === '#custom-fields'"
      :store="aspectStore"
      class="block"
      @update-custom-fields="updateCustomFields"
    />
    <add-relationships
      v-if="$route.hash === '#relationships'"
      class="block"
      :store="usersRelationshipStore"
      @update-users-relationship="updateUsersRelationship"
      @add-users-relationship="addUsersRelationship"
      @delete-users-relationship="deleteUsersRelationship"
    />
    <add-groups
      v-if="$route.hash === '#groups' && newUser"
      class="block"
      :store="groupStore"
      :new-user="newUser"
      @toggle-user="toggleUserInGroup"
    />
    <add-moments
      v-if="$route.hash === '#moments' && newUser"
      class="block"
      :store="momentStore"
      :new-user="newUser"
      @toggle-user="toggleUserInMoment"
    />
    <add-confirmation
      v-if="$route.hash === '#confirmation' && newUser"
      class="block"
      :new-user="newUser"
      @get-new-user="getNewUser"
    />
    <flex justify="space-around" class="buttons">
      <btn variant="default" basic @click.native="back">
        {{ backText }}
      </btn>
      <btn
        :disabled="
          !userStore.get('Start Date') && continueText != 'Add Another Person'
        "
        :loading="addingData"
        @click.native="next"
      >
        {{ continueText }}
      </btn>
    </flex>
  </container>
</template>

<script>
import { isValid, parseISO } from 'date-fns'
import { uniqueId } from 'lodash'

import { api } from '@/api'
import { toast } from '@/toasts'
import pageHeader from '@/components/v2/page_header.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import tabList from '@/components/v2/tabs/tab_list.vue'
import tab from '@/components/v2/tabs/tab.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'

import addInformation from '@/views/v2/add_person/add_information.vue'
import addCustomFields from '@/views/v2/add_person/add_custom_fields.vue'
import addRelationships from '@/views/v2/add_person/add_relationships.vue'
import addGroups from '@/views/v2/add_person/add_groups.vue'
import addMoments from '@/views/v2/add_person/add_moments.vue'
import addConfirmation from '@/views/v2/add_person/add_confirmation.vue'

import dateMixin from '@/mixins/v2/dateMixin'

export default {
  components: {
    pageHeader,
    grid,
    gridColumn,
    loading,
    container,
    tabList,
    tab,
    flex,
    btn,
    addInformation,
    addCustomFields,
    addRelationships,
    addGroups,
    addMoments,
    addConfirmation,
  },
  mixins: [dateMixin],
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isValid,
      pageLoading: false,
      addingData: false,
      newUser: null,
      userStore: new Map(),
      aspectStore: new Map(),
      groupStore: new Map(),
      momentStore: new Map(),
      usersRelationshipStore: new Map(),
    }
  },
  computed: {
    backText() {
      if (this.$route.hash === '#information') {
        return `Quick Add ${this.newUser && this.newUser.fullName}`
      }
      if (this.$route.hash === '#confirmation') {
        return `View ${
          (this.newUser && this.newUser.fullName) || 'New Person'
        }'s Profile`
      }
      return 'Back'
    },
    continueText() {
      if (this.$route.hash === '#confirmation') {
        return 'Add Another Person'
      }
      if (this.$route.hash === '#moments') {
        return 'Complete and Review'
      }
      return 'Continue'
    },
  },
  created() {
    if (!this.$route.hash) {
      this.$router.replace({ hash: '#information' })
    }
  },
  mounted() {
    if (
      !this.userStore.get('Start Date') &&
      this.$route.hash !== '#information' &&
      this.$route.hash !== '#confirmation'
    ) {
      this.$router.replace({ hash: '#information' })
    }
    this.getPage()
  },
  methods: {
    async getPage() {
      try {
        this.pageLoading = true
        await this.getNewUser()
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    async getNewUser() {
      try {
        this.newUser = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users/${this.id}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async next() {
      if (this.$route.hash === '#information') {
        this.$router.push({ hash: '#custom-fields' })
        return
      }
      if (this.$route.hash === '#custom-fields') {
        this.$router.push({ hash: '#relationships' })
        return
      }
      if (this.$route.hash === '#relationships') {
        this.$router.push({ hash: '#groups' })
        return
      }
      if (this.$route.hash === '#groups') {
        this.$router.push({ hash: '#moments' })
        return
      }
      if (this.$route.hash === '#moments') {
        await this.addToPerson()
        this.$router.push({ hash: '#confirmation' })
        return
      }
      if (this.$route.hash === '#confirmation') {
        this.$router.push({ name: 'people' })
      }
    },
    back() {
      if (this.$route.hash === '#information') {
        this.$router.push({ name: 'people' })
        return
      }
      if (this.$route.hash === '#custom-fields') {
        this.$router.push({ hash: '#information' })
        return
      }
      if (this.$route.hash === '#relationships') {
        this.$router.push({ hash: '#custom-fields' })
        return
      }
      if (this.$route.hash === '#groups') {
        this.$router.push({ hash: '#relationships' })
        return
      }
      if (this.$route.hash === '#moments') {
        this.$router.push({ hash: '#groups' })
        return
      }
      if (this.$route.hash === '#confirmation') {
        this.$router.push({ name: 'profile', params: { id: this.id } })
      }
    },
    updateInformation({ name, value }) {
      this.userStore = new Map(this.userStore.set(name, value))
    },
    updateCustomFields({ aspect, value }) {
      this.aspectStore = new Map(
        this.aspectStore.set(aspect.id, { name: aspect.name, value })
      )
    },
    toggleUserInGroup({ group, toggle }) {
      if (toggle) {
        this.groupStore = new Map(this.groupStore.set(group.id, group.name))
      } else {
        this.groupStore.delete(group.id)
        this.groupStore = new Map(this.groupStore)
      }
    },
    toggleUserInMoment({ moment, toggle }) {
      if (toggle) {
        this.momentStore = new Map(this.momentStore.set(moment.id, moment.name))
      } else {
        this.momentStore.delete(moment.id)
        this.momentStore = new Map(this.momentStore)
      }
    },
    addUsersRelationship(relationshipId) {
      this.usersRelationshipStore = new Map(
        this.usersRelationshipStore.set(relationshipId, [
          ...(this.usersRelationshipStore.get(relationshipId) || []),
          ...[
            {
              id: uniqueId(),
              secondUserId: parseInt(this.id, 10),
              firstUserId: null,
            },
          ],
        ])
      )
    },
    updateUsersRelationship({
      relationshipId,
      usersRelationshipId,
      field,
      value,
    }) {
      const usersRelationships = this.usersRelationshipStore.get(relationshipId)
      usersRelationships[
        usersRelationships.findIndex(
          (usersRelationship) => usersRelationship.id === usersRelationshipId
        )
      ][field] = value

      this.usersRelationshipStore = new Map(
        this.usersRelationshipStore.set(relationshipId, usersRelationships)
      )
    },
    deleteUsersRelationship({ relationshipId, usersRelationshipId }) {
      const usersRelationships = this.usersRelationshipStore.get(relationshipId)
      this.usersRelationshipStore = new Map(
        this.usersRelationshipStore.set(
          relationshipId,
          usersRelationships.filter(
            (usersRelationship) => usersRelationship.id !== usersRelationshipId
          )
        )
      )
    },
    async addToPerson() {
      /* eslint-disable no-await-in-loop */
      try {
        this.addingData = true

        try {
          await api.patch(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users/${this.newUser.id}`,
            {
              birthday: this.userStore.get('Birthday') || null,
              startDate: this.userStore.get('Start Date') || null,
              endDate: this.userStore.get('End Date') || null,
            }
          )
        } catch (error) {
          toast.error(error)
        }

        try {
          for (const [momentId] of this.momentStore.entries()) {
            await api.post(
              `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${momentId}/enrollees/individuals`,
              { userId: parseInt(this.id, 10) }
            )
          }
        } catch (error) {
          toast.error(error)
        }

        try {
          for (const [aspectId, userAspect] of this.aspectStore.entries()) {
            const [existingUserAspect] = (
              await api.get(
                `${process.env.VUE_APP_DB_ENDPOINT}/v2/user-aspects`,
                { userId: this.id, aspectId }
              )
            ).data

            if (existingUserAspect) {
              await api.patch(
                `${process.env.VUE_APP_DB_ENDPOINT}/v2/user-aspects/${existingUserAspect.id}`,
                {
                  value: isValid(parseISO(userAspect.value))
                    ? this.serializeDate(userAspect.value)
                    : userAspect.value,
                }
              )
            } else {
              await api.post(
                `${process.env.VUE_APP_DB_ENDPOINT}/v2/user-aspects`,
                {
                  userId: parseInt(this.id, 10),
                  aspectId,
                  value: isValid(parseISO(userAspect.value))
                    ? this.serializeDate(userAspect.value)
                    : userAspect.value,
                }
              )
            }
          }
        } catch (error) {
          toast.error(error)
        }

        try {
          for (const [groupId] of this.groupStore.entries()) {
            // eslint-disable-next-line no-await-in-loop
            await api.post(
              `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${groupId}/members`,
              { userId: parseInt(this.id, 10) }
            )
          }
        } catch (error) {
          toast.error(error)
        }

        try {
          for (const [
            relationshipId,
            usersRelationships,
          ] of this.usersRelationshipStore.entries()) {
            for (const usersRelationship of usersRelationships) {
              // eslint-disable-next-line no-await-in-loop
              await api.post(
                `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships`,
                {
                  relationshipId,
                  firstUserId: usersRelationship.firstUserId,
                  secondUserId: usersRelationship.secondUserId,
                }
              )
            }
          }
        } catch (error) {
          toast.error(error)
        }
      } catch (error) {
        toast.error(error)
      } finally {
        this.addingData = false
      }
      /* eslint-enable no-await-in-loop */
    },
  },
}
</script>

<style lang="less" scoped>
.block {
  width: 40vw;
  margin: 0 auto;
}

.buttons {
  margin: 1rem 0;
}
</style>
