<template>
  <div class="pagination-container">
    <i
      v-if="numOfPages > pageWindow"
      v-tippy
      :class="[
        'fas fa-angle-double-left pagination-icon',
        { disabled: disablePrevious },
      ]"
      content="First Page"
      @click="
        $router.push({
          name: $route.name,
          query: { ...$route.query, ...{ page: 1 } },
          hash: $route.hash,
        })
      "
    />
    <i
      v-tippy
      :class="[
        'fas fa-angle-left pagination-icon',
        { disabled: disablePrevious },
      ]"
      content="Previous Page"
      @click="
        $router.push({
          name: $route.name,
          query: { ...$route.query, ...{ page: previous } },
          hash: $route.hash,
        })
      "
    />
    <div :class="classes">
      <pagination-item
        v-for="page in pages"
        :key="page"
        :page="page"
        :active="page === currentPage"
        :to="{
          name: $route.name,
          query: { ...$route.query, ...{ page } },
          hash: $route.hash,
        }"
      >
        {{ page }}
      </pagination-item>
    </div>
    <i
      v-tippy
      :class="['fas fa-angle-right pagination-icon', { disabled: disableNext }]"
      content="Next Page"
      @click="
        $router.push({
          name: $route.name,
          query: { ...$route.query, ...{ page: next } },
          hash: $route.hash,
        })
      "
    />
    <i
      v-if="numOfPages > pageWindow"
      v-tippy
      :class="[
        'fas fa-angle-double-right pagination-icon',
        { disabled: disableNext },
      ]"
      content="Last Page"
      @click="
        $router.push({
          name: $route.name,
          query: { ...$route.query, ...{ page: numOfPages } },
          hash: $route.hash,
        })
      "
    />
  </div>
</template>

<script>
import { range } from 'lodash'
import paginationItem from '@/components/v2/pagination/item.vue'

export default {
  components: { paginationItem },
  props: {
    size: {
      type: String,
      default: null,
      validator: (value) =>
        ['mini', 'tiny', 'small', 'large', 'big', 'huge', 'massive'].includes(
          value
        ) || value === null,
    },
    numOfPages: {
      type: Number,
      required: true,
    },
    pageWindow: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    classes() {
      return {
        ...(this.size ? { [this.size]: true } : {}),
        ui: true,
        pagination: true,
        menu: true,
      }
    },
    currentPage() {
      return parseInt(this.$route.query.page || 1, 10)
    },
    previous() {
      return this.currentPage - 1
    },
    next() {
      return this.currentPage + 1
    },
    pages() {
      const last =
        this.currentPage + this.pageWindow < this.numOfPages
          ? this.currentPage + this.pageWindow
          : this.numOfPages
      const minPage = Math.max(1, this.numOfPages - this.pageWindow)

      if (this.currentPage > minPage) {
        return range(minPage, last + 1)
      }
      return range(this.currentPage, last + 1)
    },
    disableNext() {
      return this.currentPage === this.numOfPages
    },
    disablePrevious() {
      return this.currentPage === 1
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';
@import '~@/assets/less/text.less';

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.pagination-icon {
  min-width: 2em;
  padding: 0.5em;
  font-size: @medium-icon-font-size;
  text-align: center;
  cursor: pointer;
  border-radius: @standard-border-radius;
}

.pagination-icon:hover {
  background-color: @light-grey-hover;
}

.ui.pagination.menu {
  margin: 0 0.5em;
  border: none;
  box-shadow: none;

  .item {
    display: block;
    min-width: 2.5em;
    padding: 0.75em;
    text-align: center;
    border-radius: @standard-border-radius;
  }

  .item::before {
    display: none;
  }

  .item.active {
    font-weight: bold;
    color: @blue;
    background-color: @light-grey-hover;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
