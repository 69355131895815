<template>
  <div>
    <g-table basic single-line unstackable>
      <table-body>
        <table-row>
          <table-cell> Birthday </table-cell>
          <table-cell center-aligned>
            <date-picker
              dropdown
              variant="isoDate"
              :date="
                store.get('Birthday') ? parseISO(store.get('Birthday')) : null
              "
              @update="
                (value) =>
                  $emit('update-information', {
                    name: 'Birthday',
                    value: value,
                  })
              "
            />
          </table-cell>
        </table-row>
        <table-row>
          <table-cell> Start Date <span class="required">*</span> </table-cell>
          <table-cell center-aligned>
            <date-picker
              dropdown
              variant="isoDate"
              :date="
                store.get('Start Date')
                  ? parseISO(store.get('Start Date'))
                  : null
              "
              @update="
                (value) =>
                  $emit('update-information', {
                    name: 'Start Date',
                    value: value,
                  })
              "
            />
          </table-cell>
        </table-row>
        <table-row>
          <table-cell> End Date </table-cell>
          <table-cell center-aligned>
            <date-picker
              variant="isoDate"
              dropdown
              :date="
                store.get('End Date') ? parseISO(store.get('End Date')) : null
              "
              @update="
                (value) =>
                  $emit('update-information', {
                    name: 'End Date',
                    value: value,
                  })
              "
            />
          </table-cell>
        </table-row>
      </table-body>
    </g-table>
  </div>
</template>

<script>
import { parseISO } from 'date-fns'

import datePicker from '@/components/v2/date_picker.vue'
import gTable from '@/components/v2/table/table.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableBody from '@/components/v2/table/body.vue'
import tableRow from '@/components/v2/table/row.vue'

export default {
  components: {
    datePicker,
    gTable,
    tableCell,
    tableBody,
    tableRow,
  },
  props: {
    store: {
      type: Map,
      required: true,
    },
  },
  data() {
    return {
      parseISO,
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.required {
  color: @error-red;
}
</style>
