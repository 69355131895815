<template>
  <table :class="classes">
    <slot />
  </table>
</template>

<script>
export default {
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    unstackable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    celled: {
      type: Boolean,
      default: false,
    },
    basic: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    definition: {
      type: Boolean,
      default: false,
    },
    padded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) =>
        [
          'mini',
          'tiny',
          'small',
          'medium',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
    structured: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    one: {
      type: Boolean,
      default: false,
    },
    two: {
      type: Boolean,
      default: false,
    },
    three: {
      type: Boolean,
      default: false,
    },
    four: {
      type: Boolean,
      default: false,
    },
    five: {
      type: Boolean,
      default: false,
    },
    six: {
      type: Boolean,
      default: false,
    },
    seven: {
      type: Boolean,
      default: false,
    },
    eight: {
      type: Boolean,
      default: false,
    },
    nine: {
      type: Boolean,
      default: false,
    },
    ten: {
      type: Boolean,
      default: false,
    },
    eleven: {
      type: Boolean,
      default: false,
    },
    twelve: {
      type: Boolean,
      default: false,
    },
    thirteen: {
      type: Boolean,
      default: false,
    },
    fourteen: {
      type: Boolean,
      default: false,
    },
    fifteen: {
      type: Boolean,
      default: false,
    },
    sixteen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        fixed: this.fixed,
        unstackable: this.unstackable,
        selectable: this.selectable,
        celled: this.celled,
        'very basic': this.basic,
        compact: this.compact,
        padded: this.padded,
        definition: this.definition,
        structured: this.structured,
        'single line': this.singleLine,
        [this.size]: true,
        'one column': this.one,
        'two column': this.two,
        'three column': this.three,
        'four column': this.four,
        'five column': this.five,
        'six column': this.six,
        'seven column': this.seven,
        'eight column': this.eight,
        'nine column': this.nine,
        'ten column': this.ten,
        'eleven column': this.eleven,
        'twelve column': this.twelve,
        'thirteen column': this.thirteen,
        'fourteen column': this.fourteen,
        'fifteen column': this.fifteen,
        'sixteen column': this.sixteen,
        table: true,
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ui.fixed.table ::v-deep td,
.ui.fixed.table ::v-deep th {
  overflow: visible;
}
</style>
