var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('g-table',{attrs:{"basic":"","single-line":"","unstackable":""}},[_c('table-body',[_c('table-row',[_c('table-cell',[_vm._v(" Birthday ")]),_c('table-cell',{attrs:{"center-aligned":""}},[_c('date-picker',{attrs:{"dropdown":"","variant":"isoDate","date":_vm.store.get('Birthday') ? _vm.parseISO(_vm.store.get('Birthday')) : null},on:{"update":function (value) { return _vm.$emit('update-information', {
                  name: 'Birthday',
                  value: value,
                }); }}})],1)],1),_c('table-row',[_c('table-cell',[_vm._v(" Start Date "),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('table-cell',{attrs:{"center-aligned":""}},[_c('date-picker',{attrs:{"dropdown":"","variant":"isoDate","date":_vm.store.get('Start Date')
                ? _vm.parseISO(_vm.store.get('Start Date'))
                : null},on:{"update":function (value) { return _vm.$emit('update-information', {
                  name: 'Start Date',
                  value: value,
                }); }}})],1)],1),_c('table-row',[_c('table-cell',[_vm._v(" End Date ")]),_c('table-cell',{attrs:{"center-aligned":""}},[_c('date-picker',{attrs:{"variant":"isoDate","dropdown":"","date":_vm.store.get('End Date') ? _vm.parseISO(_vm.store.get('End Date')) : null},on:{"update":function (value) { return _vm.$emit('update-information', {
                  name: 'End Date',
                  value: value,
                }); }}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }