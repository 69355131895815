<template>
  <grid centered>
    <grid-column eight>
      <list middle-aligned>
        <list-item v-for="group in groups" :key="`${group.id}-group`">
          <list-content>
            <flex align="center" justify="space-between">
              {{ group.name }}
              <checkbox
                v-if="!group.isSmartGroup"
                name="group"
                :initial-value="!!store.get(group.id)"
                @update="
                  (value) => $emit('toggle-user', { group, toggle: value })
                "
              />
              <span v-else class="grey enrolled-text">
                Added Automatically
              </span>
            </flex>
          </list-content>
        </list-item>
        <list-item
          v-for="group in enrolledGroups"
          :key="`${group.id}-enrolled-group`"
        >
          <list-content>
            <flex align="center" justify="space-between">
              {{ group.name }}
              <span class="grey enrolled-text"> Already Added </span>
            </flex>
          </list-content>
        </list-item>
        <placeholder v-if="groups.length === 0" centered light>
          There are no other groups to add {{ newUser.fullName }} to
        </placeholder>
      </list>
      <pagination
        v-if="pagination && pagination.lastPage > 1"
        :num-of-pages="pagination.lastPage"
      />
    </grid-column>
  </grid>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import flex from '@/components/v2/flex.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import listContent from '@/components/v2/list/content.vue'
import checkbox from '@/components/v2/checkbox.vue'
import placeholder from '@/components/v2/placeholder.vue'

export default {
  components: {
    grid,
    gridColumn,
    pagination,
    flex,
    list,
    listItem,
    listContent,
    checkbox,
    placeholder,
  },
  props: {
    store: {
      type: Map,
      required: true,
    },
    newUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      groups: [],
      enrolledGroups: [],
      pagination: null,
      loading: false,
    }
  },
  mounted() {
    this.getGroups()
  },
  methods: {
    async getGroups() {
      try {
        this.loading = true
        const response = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups`,
          {
            page: parseInt(this.$route.query.page || 1, 10),
            isSmartGroup: 0,
            excludeUser: this.newUser.id,
            sort: 'name,id',
          }
        )
        this.groups = response.data
        this.pagination = response.cursor

        this.enrolledGroups = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/groups`, {
            memberId: this.newUser.id,
            sort: 'name,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.enrolled-text {
  text-align: right;
}
</style>
