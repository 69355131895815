<template>
  <div :class="classes">
    <btn v-if="button" :loading="loading" variant="default">
      {{ placeholder }}
    </btn>
    <div v-if="icon" v-tippy content="Select Date" class="cal-icon">
      <i class="large grey calendar icon" />
    </div>
    <div v-if="dropdown" :class="['ui input left icon', { loading: loading }]">
      <i class="calendar icon" />
      <input
        ref="datePicker"
        v-tippy
        content="Select Date"
        class="date-picker"
        type="text"
        autocomplete="off"
        :name="name"
        :placeholder="placeholder"
        @focus="() => (typing = true)"
        @blur="onBlur"
        @keydown="onEnter"
      />
    </div>
  </div>
</template>

<script>
import jquery from 'jquery'
import { formatISO } from 'date-fns'
import dateMixin from '@/mixins/v2/dateMixin'
import btn from '@/components/v2/btn.vue'

export default {
  components: { btn },
  mixins: [dateMixin],
  props: {
    abbreviated: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Date,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select Date',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    firstDayOfWeek: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: 'Date',
    },
    variant: {
      type: String,
      validator: (value) => ['isoDate', 'jsDate'].includes(value),
      default: 'jsDate',
    },
    minDate: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      typing: false,
    }
  },
  computed: {
    classes() {
      return {
        ui: true,
        calendar: true,
        disabled: this.disabled,
        loading: this.loading,
      }
    },
  },
  watch: {
    date: {
      handler() {
        this.setValue()
      },
    },
  },
  mounted() {
    this.init()
    this.setValue()
  },
  beforeDestroy() {
    jquery(this.$el).removeData()
    jquery(this.$el).remove()
  },
  methods: {
    init() {
      jquery(this.$el).calendar({
        type: 'date',
        constantHeight: true,
        selectAdjacentDays: true,
        firstDayOfWeek: this.firstDayOfWeek,
        formatter: {
          date: (date) => {
            if (!date) {
              return ''
            }

            if (this.abbreviated) {
              return this.formatDate(date)
            }

            return this.formatDate(date, true)
          },
        },
        onChange: (date) => {
          if (date && !this.typing) {
            this.$refs.datePicker.blur()
          }
        },
        onSelect: () => {
          this.typing = false
        },
        onShow: () => {
          this.setMinDate(this.minDate)
        },
        onHide: () => {
          this.setMinDate(null)
        },
      })
    },
    setValue() {
      if (this.date) {
        // https://fomantic-ui.com/modules/calendar.html#/usage
        jquery(this.$el).calendar('set date', this.date, true, false)
      } else {
        jquery(this.$el).calendar('clear')
      }
    },
    onBlur() {
      const date = jquery(this.$el).calendar('get date')
      this.typing = false
      if (
        (date && !this.date) ||
        (date && this.date.getTime() !== date.getTime())
      ) {
        this.updateDate(date)
      }
    },
    onEnter({ keyCode }) {
      if (keyCode === 13) {
        this.$refs.datePicker.blur()
      }
    },
    setMinDate(date) {
      if (date) {
        jquery(this.$el).calendar('set minDate', date)
      } else {
        jquery(this.$el).calendar('set minDate', null)
      }
    },
    updateDate(date) {
      if (this.variant === 'isoDate') {
        this.$emit('update', formatISO(date, { representation: 'date' }))
      } else if (this.variant === 'jsDate') {
        this.$emit('update', date)
      } else {
        throw new Error(`unknown date variant ${this.variant}`)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';

.ui.calendar {
  .calendar.icon {
    left: 0;
  }

  .cal-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5em;
    height: 2.5em;
    margin: 0 0.5em;
    cursor: pointer;
    border-radius: @standard-border-radius;

    .calendar.icon {
      margin: 0;
    }

    &:hover {
      background-color: @light-grey-hover;
    }
  }

  .date-picker {
    border: none;

    &::placeholder {
      color: @black;
    }

    &:hover {
      cursor: pointer;
      background-color: @light-grey-hover;
    }

    &:focus {
      cursor: text;
      background-color: @white;
      box-shadow: @focus-box-shadow-border;
    }
  }
}
</style>
