<template>
  <div :class="classes">
    <input v-model="checked" type="checkbox" />
    <label><slot /></label>
  </div>
</template>

<script>
import jquery from 'jquery'

export default {
  props: {
    initialValue: {
      type: Boolean,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.initialValue,
    }
  },
  computed: {
    classes() {
      return {
        ui: true,
        checkbox: true,
      }
    },
  },
  watch: {
    checked: {
      handler(newValue) {
        if (newValue !== this.initialValue) {
          this.$emit('update', newValue)
        }
      },
    },
    initialValue: {
      handler(newValue) {
        this.checked = newValue
        this.syncJquery()
      },
    },
    disabled: {
      handler() {
        this.syncJquery()
      },
    },
  },
  mounted() {
    this.syncJquery()
  },
  beforeDestroy() {
    jquery(this.$el).removeData()
    jquery(this.$el).remove()
  },
  methods: {
    syncJquery() {
      jquery(this.$el).checkbox(this.initialValue ? 'check' : 'uncheck')

      if (this.disabled) {
        jquery(this.$el).checkbox('disable')
      } else {
        jquery(this.$el).checkbox('enable')
      }
    },
  },
}
</script>
