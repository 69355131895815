<template>
  <tr :class="classes">
    <slot />
  </tr>
</template>

<script>
export default {
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    collapsing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        error: this.error,
        selectable: this.selectable,
        collapsing: this.collapsing,
      }
    },
  },
}
</script>
