<template>
  <div>
    <loading v-if="loading" :loading="loading" />
    <g-table basic single-line unstackable>
      <table-body>
        <table-row v-for="aspect in aspects" :key="aspect.id">
          <table-cell>
            {{ aspect.name }}
          </table-cell>
          <table-cell>
            <date-picker
              v-if="aspect.type === 'date'"
              dropdown
              :date="
                new Date(store.get(aspect.id) && store.get(aspect.id).value) ||
                null
              "
              @update="
                (value) => $emit('update-custom-fields', { aspect, value })
              "
            />
            <editable-text
              v-if="aspect.type === 'text'"
              :placeholder="`Add ${aspect.name}`"
              :value="store.get(aspect.id) && store.get(aspect.id).value"
              @update="
                (value) => $emit('update-custom-fields', { aspect, value })
              "
            />
          </table-cell>
        </table-row>
      </table-body>
    </g-table>
    <pagination
      v-if="pagination && pagination.lastPage > 1"
      :num-of-pages="pagination.lastPage"
    />
  </div>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import datePicker from '@/components/v2/date_picker.vue'
import editableText from '@/components/v2/editable_text.vue'
import gTable from '@/components/v2/table/table.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableBody from '@/components/v2/table/body.vue'
import tableRow from '@/components/v2/table/row.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import loading from '@/components/v2/loading.vue'

export default {
  components: {
    datePicker,
    editableText,
    gTable,
    tableCell,
    tableBody,
    tableRow,
    pagination,
    loading,
  },
  props: {
    store: {
      type: Map,
      required: true,
    },
  },
  data() {
    return {
      aspects: [],
      pagination: null,
      loading: false,
    }
  },
  mounted() {
    this.getAspects()
  },
  methods: {
    async getAspects() {
      try {
        this.loading = true
        const response = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects`,
          {
            page: parseInt(this.$route.query.page || 1, 10),
            sort: 'isEditable,id',
            isEditable: 1,
          }
        )
        this.aspects = response.data
        this.pagination = response.cursor
      } catch (error) {
        toast.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
