<template>
  <loading v-if="loading" :loading="loading" />
  <grid v-else>
    <grid-column centered sixteen>
      <g-image
        :src="addPersonSuccess"
        alt="party and party goers"
        rounded
        centered
        size="medium"
      />
      <h3>{{ newUser && newUser.fullName }} has been added!</h3>
    </grid-column>

    <grid-column centered sixteen>
      <loading v-if="userAspectsLoading" :loading="userAspectsLoading" />
      <g-table v-else basic single-line unstackable fixed class="block">
        <h4>
          <i class="circular fas fa-check icon" />
          Information
        </h4>
        <table-body>
          <table-row>
            <table-cell> Name </table-cell>
            <table-cell>
              {{ newUser && newUser.fullName }}
            </table-cell>
          </table-row>
          <table-row>
            <table-cell> Work Email </table-cell>
            <table-cell>
              {{ newUser && newUser.email }}
            </table-cell>
          </table-row>
          <table-row>
            <table-cell> Personal Email </table-cell>
            <table-cell>
              {{ newUser && newUser.personalEmail }}
            </table-cell>
          </table-row>
          <table-row>
            <table-cell> Birthday </table-cell>
            <table-cell>
              {{
                newUser && newUser.birthday
                  ? formatDate(parseISO(newUser && newUser.birthday), true)
                  : ''
              }}
            </table-cell>
          </table-row>
          <table-row>
            <table-cell> Start Date </table-cell>
            <table-cell>
              {{
                newUser && newUser.startDate
                  ? formatDate(parseISO(newUser && newUser.startDate), true)
                  : ''
              }}
            </table-cell>
          </table-row>
          <table-row>
            <table-cell> End Date </table-cell>
            <table-cell>
              {{
                newUser && newUser.endDate
                  ? formatDate(parseISO(newUser && newUser.endDate), true)
                  : ''
              }}
            </table-cell>
          </table-row>
          <table-row v-for="userAspect in userAspects" :key="userAspect.id">
            <table-cell>
              {{ userAspect.aspect.name }}
            </table-cell>
            <table-cell>
              {{
                isValid(parseISO(userAspect.value))
                  ? formatDate(parseISO(userAspect.value), true)
                  : userAspect.value
              }}
            </table-cell>
          </table-row>
        </table-body>
      </g-table>
    </grid-column>

    <grid-column sixteen>
      <loading
        v-if="usersRelationshipsLoading"
        :loading="usersRelationshipsLoading"
      />
      <div v-else class="block">
        <h4>
          <i class="circular fas fa-check icon" />
          Relationships
        </h4>

        <list middle-aligned relaxed>
          <list-item
            v-for="(usersRelationship, index) in firstUserUsersRelationship"
            :key="usersRelationship.id"
          >
            <flex v-if="index === 0" align="center" class="relationship">
              <strong>
                {{
                  getRelationshipFromUsersRelationship(usersRelationship).name
                }}
              </strong>
            </flex>
            <dropdown
              name="View Role One"
              scrolling
              hide-caret
              default-text="Selection Disabled"
              disabled
              :initial-value="usersRelationship.firstUserId"
            >
              <dropdown-item
                v-for="user in users"
                :key="user.id"
                :value="user.id"
              >
                <g-image
                  avatar
                  :src="user.avatar || defaultUserAvatar"
                  alt="Role one avatar for relationship"
                />
                {{ user.fullName }}
              </dropdown-item>
            </dropdown>
            <i class="fas fa-long-arrow-alt-right grey connection-icon" />
            <dropdown
              name="View Role Two"
              scrolling
              floating
              hide-caret
              default-text="Selection Disabled"
              disabled
              :initial-value="usersRelationship.secondUserId"
            >
              <dropdown-item
                v-for="user in users"
                :key="user.id"
                :value="user.id"
              >
                <g-image
                  avatar
                  :src="user.avatar || defaultUserAvatar"
                  alt="Role two avatar for relationship"
                />
                {{ user.fullName }}
              </dropdown-item>
            </dropdown>
          </list-item>

          <list-item
            v-for="(usersRelationship, index) in secondUserUsersRelationship"
            :key="usersRelationship.id"
          >
            <flex v-if="index === 0" align="center" class="relationship">
              <strong>
                {{
                  getRelationshipFromUsersRelationship(usersRelationship).name
                }}
              </strong>
            </flex>
            <dropdown
              name="View Role One"
              scrolling
              hide-caret
              default-text="Selection Disabled"
              disabled
              :initial-value="usersRelationship.firstUserId"
            >
              <dropdown-item
                v-for="user in users"
                :key="user.id"
                :value="user.id"
              >
                <g-image
                  avatar
                  :src="user.avatar || defaultUserAvatar"
                  alt="Role one avatar for relationship"
                />
                {{ user.fullName }}
              </dropdown-item>
            </dropdown>
            <i class="fas fa-long-arrow-alt-right grey connection-icon" />
            <dropdown
              name="View Role Two"
              scrolling
              floating
              hide-caret
              default-text="Selection Disabled"
              disabled
              :initial-value="usersRelationship.secondUserId"
            >
              <dropdown-item
                v-for="user in users"
                :key="user.id"
                :value="user.id"
              >
                <g-image
                  avatar
                  :src="user.avatar || defaultUserAvatar"
                  alt="Role two avatar for relationship"
                />
                {{ user.fullName }}
              </dropdown-item>
            </dropdown>
          </list-item>
          <placeholder
            v-if="
              firstUserUsersRelationship.length === 0 &&
              secondUserUsersRelationship.length === 0
            "
            light
          >
            No relationships added
          </placeholder>
        </list>
      </div>
    </grid-column>

    <grid-column sixteen>
      <loading v-if="groupsLoading" :loading="groupsLoading" />
      <div v-else class="block">
        <h4>
          <i class="circular fas fa-check icon" />
          Groups
        </h4>

        <list middle-aligned>
          <list-item v-for="group in enrolledGroups" :key="group.id">
            <list-content>
              {{ group.name }}
            </list-content>
          </list-item>
          <placeholder v-if="enrolledGroups.length === 0" light>
            No groups added
          </placeholder>
        </list>
      </div>
    </grid-column>
    <grid-column sixteen>
      <loading v-if="momentsLoading" :loading="momentsLoading" />
      <div v-else class="block">
        <h4>
          <i class="circular fas fa-check icon" />
          Moments
        </h4>
        <list middle-aligned>
          <list-item v-for="moment in enrolledMoments" :key="moment.id">
            <list-content>
              <flex wrap="wrap" align="center" gap="0.5rem">
                <moment-icon
                  :hex-color="moment.color"
                  :icon="moment.icon"
                  size="medium"
                />
                {{ moment.name }}
              </flex>
            </list-content>
          </list-item>
          <placeholder v-if="enrolledMoments.length === 0" light>
            No moments added
          </placeholder>
        </list>
      </div>
    </grid-column>
  </grid>
</template>

<script>
import { isValid, parseISO } from 'date-fns'

import { api } from '@/api'
import { toast } from '@/toasts'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import loading from '@/components/v2/loading.vue'
import gTable from '@/components/v2/table/table.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableBody from '@/components/v2/table/body.vue'
import tableRow from '@/components/v2/table/row.vue'
import flex from '@/components/v2/flex.vue'
import gImage from '@/components/v2/image.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import listContent from '@/components/v2/list/content.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import placeholder from '@/components/v2/placeholder.vue'
import momentIcon from '@/components/v2/moment_icon.vue'

import addPersonSuccess from '@/assets/img/add_person_success.png'
import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'

import dateMixin from '@/mixins/v2/dateMixin'

export default {
  components: {
    grid,
    gridColumn,
    loading,
    gTable,
    tableCell,
    tableBody,
    tableRow,
    flex,
    gImage,
    list,
    listItem,
    listContent,
    dropdown,
    dropdownItem,
    placeholder,
    momentIcon,
  },
  mixins: [dateMixin],
  props: {
    newUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isValid,
      parseISO,
      addPersonSuccess,
      defaultUserAvatar,
      enrolledMoments: [],
      enrolledGroups: [],
      userAspects: [],
      firstUserUsersRelationship: [],
      secondUserUsersRelationship: [],
      users: [],
      momentsLoading: false,
      groupsLoading: false,
      userAspectsLoading: false,
      usersRelationshipsLoading: false,
      usersLoading: false,
    }
  },
  computed: {
    loading() {
      return (
        this.momentsLoading &&
        this.groupsLoading &&
        this.userAspectsLoading &&
        this.usersRelationshipsLoading &&
        this.usersLoading
      )
    },
  },
  mounted() {
    this.$emit('get-new-user')
    this.getSection()
  },
  methods: {
    async getSection() {
      await this.getEnrolledMoments()
      await this.getEnrolledGroups()
      await this.getUsers()
      await this.getUserAspects()
      await this.getUsersRelationships()
    },
    async getEnrolledMoments() {
      try {
        this.momentsLoading = true
        this.enrolledMoments = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/moments`, {
            userId: this.newUser.id,
            sort: 'name,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.momentsLoading = false
      }
    },
    async getEnrolledGroups() {
      try {
        this.groupsLoading = true
        this.enrolledGroups = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/groups`, {
            memberId: this.newUser.id,
            sort: 'name,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.groupsLoading = false
      }
    },
    async getUserAspects() {
      try {
        this.userAspectsLoading = true
        this.userAspects = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/user-aspects`, {
            userId: this.newUser.id,
            isEditable: 1,
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.userAspectsLoading = false
      }
    },
    async getUsersRelationships() {
      try {
        this.usersRelationshipsLoading = true
        this.firstUserUsersRelationship = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships`,
            {
              firstUserId: this.newUser.id,
              userStatus: ['active', 'onboarding'],
            }
          )
        ).data

        this.secondUserUsersRelationship = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships`,
            {
              secondUserId: this.newUser.id,
              userStatus: ['active', 'onboarding'],
            }
          )
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.usersRelationshipsLoading = false
      }
    },
    async getUsers(search) {
      let response
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              ...(search ? { search } : {}),
              status: ['active', 'onboarding'],
            }
          )
          this.users = this.users.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getRelationshipFromUsersRelationship(usersRelationship) {
      return (
        await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships/${usersRelationship.relationshipId}`
        )
      ).data
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.ui.dropdown.disabled {
  opacity: 1;
}

.connection-icon {
  margin: 0 1.5rem;
}

.fa-check.icon {
  color: @green;
  background-color: @light-green;
  box-shadow: none;
}
</style>
