<template>
  <loading v-if="loading" :loading="loading" />
  <grid v-else>
    <grid-column sixteen>
      <list middle-aligned>
        <list-item v-for="moment in moments" :key="`${moment.id}-moment`">
          <list-content>
            <flex align="center" justify="space-between">
              <flex wrap="wrap" align="center" gap="0.5rem">
                <moment-icon
                  :hex-color="moment.color"
                  :icon="moment.icon"
                  size="medium"
                />
                {{ moment.name }}
              </flex>
              <checkbox
                name="moment"
                :initial-value="!!store.get(moment.id)"
                @update="
                  (value) => $emit('toggle-user', { moment, toggle: value })
                "
              />
            </flex>
          </list-content>
        </list-item>
        <list-item
          v-for="moment in enrolledMoments"
          :key="`${moment.id}-enrolled-moment`"
        >
          <list-content>
            <flex align="center" justify="space-between">
              <flex align="center" gap="0.5rem">
                <moment-icon
                  :hex-color="moment.color"
                  :icon="moment.icon"
                  size="medium"
                />
                {{ moment.name }}
              </flex>
              <span class="grey enrolled-text">Already Enrolled</span>
            </flex>
          </list-content>
        </list-item>
        <placeholder v-if="moments.length === 0" centered light>
          There are no other moments to enroll {{ newUser.fullName }} in
        </placeholder>
      </list>
      <pagination
        v-if="pagination && pagination.lastPage > 1"
        :num-of-pages="pagination.lastPage"
      />
    </grid-column>
  </grid>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import flex from '@/components/v2/flex.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import listContent from '@/components/v2/list/content.vue'
import checkbox from '@/components/v2/checkbox.vue'
import placeholder from '@/components/v2/placeholder.vue'
import momentIcon from '@/components/v2/moment_icon.vue'
import loading from '@/components/v2/loading.vue'

export default {
  components: {
    grid,
    gridColumn,
    pagination,
    flex,
    list,
    listItem,
    listContent,
    checkbox,
    placeholder,
    momentIcon,
    loading,
  },
  props: {
    store: {
      type: Map,
      required: true,
    },
    newUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moments: [],
      enrolledMoments: [],
      pagination: null,
      loading: false,
    }
  },
  mounted() {
    this.getMoments()
  },
  methods: {
    async getMoments() {
      try {
        this.loading = true
        const response = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments`,
          {
            page: parseInt(this.$route.query.page || 1, 10),
            excludeUser: this.newUser.id,
            sort: 'name,id',
          }
        )
        this.moments = response.data
        this.pagination = response.cursor

        this.enrolledMoments = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/moments`, {
            userId: this.newUser.id,
            sort: 'name,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.enrolled-text {
  text-align: right;
}
</style>
